import "dnx-web-components/vendor/amcharts/amcharts.js";
import "dnx-web-components/vendor/amcharts/pie.js";
import "dnx-web-components/vendor/amcharts/serial.js";
import "dnx-web-components/vendor/amcharts/themes/light.js";
import "dnx-web-components/vendor/amcharts/plugins/responsive/responsive.min.js";
import "dnx-web-components/vendor/amcharts/plugins/export/export.min.js";
import "dnx-web-components/vendor/amcharts/xy.js";
import "dnx-web-components/vendor/amcharts/gantt.js";

window.AmCharts_path =
   __webpack_public_path__.replace(/\/$/, "") + "/amcharts/";
window.AmCharts.isReady = true;

export default window.AmCharts;

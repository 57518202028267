/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import {Platform} from "@harbor/platform";
import featureFlags from "../../deprecated/FeatureFlags";

/**
 * @type {Object.<string, import("@harbor/platform/Filter").PlatformFilterResolver<unknown>>}
 */
const filterResolvers = {
  isCloud(value) {
    const isCloud = window.platformUI.cloudProvider !== "none";
    return typeof value !== "boolean" || isCloud === value;
  },
  isEn(value) {
    const ff = featureFlags.isEnabled("cisco.dna.core.system/enable-EN-Portal");
    return (
      (typeof value === "boolean" && window.platformUI.auth.enEnabled === value) || ff
    );
  },
  cloudProvider(value) {
    const values = Array.isArray(value) ? value : [value];
    return values.some(v => v === window.platformUI.cloudProvider);
  },
  appRole(value) {
    return (
      typeof value !== "string" || window.platformUI.appRole === value.toLowerCase()
    );
  },
  pluginExists(pluginId, {platform}) {
    return platform.pluginRegistry.contributionIndex.getIndex()[pluginId] !== undefined;
  },
  userRoles(value) {
    const values = Array.isArray(value) ? value : [value];
    const {roles = []} = window.platformUI.auth;
    return values.some(v => roles.includes(v));
  },
  offerId(value) {
    const id = window.platformUI.offerId;
    return id === value;
  },
  isAddressFamily(value) {
    return value === window.platformUI.addressFamily;
  },
  isFipsEnabled(value) {
    return value === window.platformUI.fipsEnabled; 
  },
};

const platform = new Platform({
  development: process.env.NODE_ENV === "development",
  locale: window._i18n?.locale ?? "en",
  pluginConfigs: window.platformUI.pluginConfigs,
  pluginPaths: []
});

platform.filter.addResolvers(filterResolvers);

export {platform};

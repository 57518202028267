/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */

import "./css/reset.css";
import "./css/global.css";
import "./css/utility-classes.css";
import "./css/dnx-monkeypatch.css";
import "./css/bootstrap-grid.css";
import "@cisco-dna/css/dist/dna.css";
import "@cisco-dna/css/dist/dnac-icon.css";

import rjs from "requirejs-umd";

// @ts-expect-error JS file
import PluginContributions from "./core/utils/PluginContributions";
import * as Platform from "./core/harbor-platform";
import {
   dnaIcons,
   amCharts,
   dnxWebComponents,
   dnxReactComponents,
   dnxTable,
   dnxTableReact,
   dnxChunksReact,
   dnxChunks,
} from "./externals/dnx-web-components";
import { initHarborElements } from "./externals/harbor-elements";

import { packages } from "./package_versions";
import { polykill } from "./polykill";
import * as DevPluginStore from "./DevPluginStore";
import { createSharedInstance } from "./externals/axios";
import * as AxiosLatest from "./externals/axios-1.x";
import { promiseLoader, serviceLoader } from "./AmdLoaders";
import { setLanguage } from "./Language";

const STARTUP_ALLOWED_PLUGINS = ["cisco.harbor.pui.startup"];

// Externals are packages that we bundle and make globally available for plugin teams
// Their purpose is to minimize bundle size by sharing the most common packages
const externals = (): Record<string, () => unknown> => {
   const axios = createSharedInstance();
   const axiosLatest = AxiosLatest.createSharedInstance();

   const react = () => import(/* webpackChunkName: "react" */ "react");
   const reactDom = () =>
      import(/* webpackChunkName: "react-dom" */ "react-dom");

   return {
      "dna/shared-libs/axios": () => axios,
      "axios": () => axiosLatest,
      "dna/shared-libs/@harbor/cnv": () =>
         import(/* webpackChunkName: "cnv"*/ "./externals/cnv"),
      "dna/shared-libs/@harbor/cnv-export": () =>
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
         // @ts-ignore
         import(/* webpackChunkName: "cnv-export"*/ "@harbor/cnv-export"),
      "dna/shared-libs/react": () => react(),
      "react": () => react(),
      "dna/shared-libs/react-dom": () => reactDom(),
      "react-dom": () => reactDom(),

      "@cisco-dna/dnx-web-components": () => dnxWebComponents(),
      "@cisco-dna/dnx-web-components/table": () => dnxTable(),
      "@cisco-dna/dnx-web-components/chunks": () => dnxChunks(),
      "@cisco-dna/dnx-react-components": () => dnxReactComponents(),
      "@cisco-dna/dnx-react-components/table": () => dnxTableReact(),
      "@cisco-dna/dnx-react-components/chunks": () => dnxChunksReact(),
      "@cisco-dna/icons": () => dnaIcons(),
      "@harbor/elements": () =>
         import(/* webpackChunkName: "harbor-elements" */ "@harbor/elements"),
      "@harbor/elements/reactWrapper": () =>
         import(
            // @ts-expect-error Untyped
            /* webpackChunkName: "harbor_elements-react-wrapper" */ "@harbor/elements/utils/react/wrapper"
         ),

      // DNA specific runtime modules, mostly available for backwards compatibility.
      // each of the below modules have (or at least, should have) a better version
      "core/plugin/featureFlags": () => require("./deprecated/FeatureFlags"),
      "core/utils/PluginContributions": () =>
         require("./core/utils/PluginContributions"),
      "core/utils/i18n": () => require("./core/utils/i18n"),
      "core/utils/parseReference": () => require("./core/utils/parseReference"),
      "core/utils/serviceRegistry": () =>
         require("./core/utils/serviceRegistry"),
      "core/utils/viewRegistry": () => require("./core/utils/viewRegistry"),

      // we define amcharts libs because they're referenced as externals by both
      // dnx components.  in an ideal future, we'll remove the
      // global defines and have them tucked privately into dnx components
      "amcharts": () => amCharts(),
      "amcharts.serial": () => amCharts(),
      "amcharts.themes.light": () => amCharts(),
      "amcharts.pie": () => amCharts(),
      "amcharts.responsive": () => amCharts(),
      "amcharts.export": () => amCharts(),
      "amcharts.xy": () => amCharts(),
      "amcharts.gantt": () => amCharts(),
   };
};

// starting p3.0, these select packages were removed from the global scope
// in favor of plugin teams managing the dependency themselves.
// since we still support cloud plugins, we can lazily load them back
const deprecatedExternals = () => {
   const deprecatedChunk = () =>
      import(
         /* webpackChunkName: "pui-deprecated"*/ "./externals/pui-deprecated"
      );

   return {
      "jquery": () => deprecatedChunk().then((r) => r.jquery),
      "underscore": () => deprecatedChunk().then((r) => r.underscore),
      "webui-popover": () => deprecatedChunk().then((r) => r.popover),
      "dna/shared-libs/redux": () => deprecatedChunk().then((r) => r.redux),
      "dna/shared-libs/redux/v3": () => deprecatedChunk().then((r) => r.redux),
      "dna/shared-libs/react-redux": () =>
         deprecatedChunk().then((r) => r.reactRedux),
      "dna/shared-libs/react-redux/v5": () =>
         deprecatedChunk().then((r) => r.reactRedux),
      "dna/shared-libs/redux-thunk": () =>
         deprecatedChunk().then((r) => r.reduxThunk),
      "dna/shared-libs/redux-thunk/v2": () =>
         deprecatedChunk().then((r) => r.reduxThunk),
      "dna/shared-libs/redux-promise-middleware": () =>
         deprecatedChunk().then((r) => r.reduxPromiseMiddleware),
      "dna/shared-libs/redux-promise-middleware/v4": () =>
         deprecatedChunk().then((r) => r.reduxPromiseMiddleware),
      "dna/shared-libs/rxjs": () => deprecatedChunk().then((r) => r.rxjs),
      "dna/shared-libs/rxjs/v6": () => deprecatedChunk().then((r) => r.rxjs),
      "dna/shared-libs/moment": () =>
         import(/* webpackChunkName: "moment" */ "moment"),
   };
};

const initExternals = () => {
   window.define("promisify", promiseLoader);

   const ext = externals();
   if (process.env.LEGACY_EXTERNALS) {
      Object.assign(ext, deprecatedExternals());
   }

   const moduleInterop = (exported: any) => {
      if (!exported) return undefined;
      else if ("default" in exported) return exported.default;
      else return exported;
   };

   for (const [alias, module] of Object.entries(ext)) {
      window.define(`__lazy__${alias}`, module);
      window.define(alias, [`promisify!__lazy__${alias}`], moduleInterop);
   }
};

const debug =
   process.env.NODE_ENV === "production"
      ? () => {}
      : console.debug.bind(console);

// 🚀 Start platform-ui
void (async function start() {
   debug("Set up webpack");
   // todo: switch to using process.env.BASE_PATH
   __webpack_public_path__ = window.platformUI.config.basePath + "dist/";
   window.RELEASE_NAME = localStorage.getItem("releaseName");
   window.RELEASE_VERSION = localStorage.getItem("releaseVersion");
   setLanguage();

   // Setup global AMD loader
   debug("Init requirejs globals");
   window.requirejs = rjs.requirejs;
   window.require = rjs.require;
   window.define = rjs.define;
   window.define("dna-service", serviceLoader);
   window.require.config({
      baseUrl:
         typeof window !== "undefined"
            ? window.platformUI.config.basePath
            : "/",
      waitSeconds: 0,
   });

   debug("Polykilling globals");
   polykill([
      [window, "Promise"],
      [window, "fetch"],
      [window, "cisco"],
      [window, "appcontext"],
      [window, "platformUI"],
      [window.Array, "from"],
      [window.Object, "assign"],
   ]);

   debug("Initialize externals");
   initExternals();
   initHarborElements();

   // Helps with inspecting a cluster, to see what version of a package is installed
   Object.defineProperty(window, "__HARBOR_INTERNAL_ENV__", {
      get: () => packages(),
   });

   // When running platform in development mode,
   // we'll auto-add core plugins to the registry to simplify things
   if (process.env.INJECT_DEV_PLUGINS) {
      debug("Injecting development plugins", process.env.INJECT_DEV_PLUGINS);
      Platform.platform.pluginRegistry.addDevPlugin(
         process.env.INJECT_DEV_PLUGINS
      );
   }

   debug("Initialize platform");
   await Platform.platform.init();
   DevPluginStore.createInternalPluginAPI();

   // Initialize ui-config filters first before
   const filterContribs = new PluginContributions(
      "cisco.dna.core"
   ).forExtensionPoint("filter");

   await Promise.all(
      filterContribs.map(async (filter: any) => {
         const name = filter.data.name;
         const callback = await filter.loadModule(filter.data.module);
         Platform.platform.filter.addResolver(name, callback);
         debug(`Add filter '${name}' from ${filter.sourcePluginId}`);
      })
   );

   const startupPlugins = new PluginContributions("cisco.dna.core")
      .forExtensionPoint("boot")
      .filter((plugin: any) =>
         STARTUP_ALLOWED_PLUGINS.includes(plugin.sourcePluginId)
      );

   await Promise.all(
      startupPlugins.map(async (entry: any) => {
         const fn = await entry.loadModule();
         try {
            debug(`Executing startup for ${entry.sourcePluginId}`);
            await fn();
         } catch (e) {
            console.error(
               `An error occured while running startup function for '${entry.sourcePluginId}':`,
               e
            );
         }
      })
   );
})();

const supportedLanguages = ["en", "ja", "ko", "zh"];
const defaultLanguage = "en";

const standardizeCode = (str: string) => str.toLowerCase().replace("_", "-");
const locale = (lang: string) => lang.split("-")[0] || "";
const matchCode = (a: string, b: string) => locale(a) === locale(b);
const isSupported = (lang: string) =>
   supportedLanguages.some((it) => matchCode(lang, it));

declare global {
   interface Window {
      /** Used by i18n webpack loader */
      _i18n: { locale: string; rawLocale: string };
   }
}

/**
 * Configure our translation loader to use the first supported language the user has specified.
 */
export function setLanguage(): void {
   const browserCodes = window.navigator.languages.map(standardizeCode);
   const lang = browserCodes.find(isSupported) ?? defaultLanguage;
   window._i18n = { locale: locale(lang), rawLocale: lang };
}

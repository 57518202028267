interface DnxContextElement extends HTMLElement {
   version: string;
}

export interface Packages {
   [name: string]: string;
}

export const packages = (): Record<string, string> => ({
   "harbor": process.env.HARBOR_PACKAGE_VERSION || "",
   "dnx-web-components": (
      document.createElement("dnx-context") as DnxContextElement
   ).version,
   "react": process.env.REACT_VERSION || "",
});
